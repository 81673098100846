import * as React from 'react'
import Arrow from './Arrow'
import styled, { css } from 'styled-components'
import { yPad } from '../utils/theme'
import { Flex } from 'grid-styled'
import Button from './Button'
import { Link } from 'gatsby'

interface IContainerProps {
  flushTop: boolean
}

const Container = styled<IContainerProps, any>('section')`
  ${yPad};
  background-image: linear-gradient(0deg, #f0efeb 0, #f9f8f7 100%);

  color: ${props => props.theme.colors.dark};

  h2 {
    color: ${props => props.theme.colors.dark};
    text-align: center;
    margin-bottom: 3rem;
  }

  i {
    color: ${props => props.theme.colors.primary};
    font-style: normal;
  }

  ${props =>
    props.flushTop &&
    css`
      padding-top: 3rem !important;
    `};
`

const Testimonial = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  /* min-height: 280px; */
  text-align: center;

  p {
    margin-top: 0;
    font-size: 25px;
    font-weight: 300;
    line-height: 1.68;
    max-width: 800px;

    @media (max-width: 640px) {
      font-size: 19px;
    }
  }

  svg {
    margin: 0 4rem;
    cursor: pointer;

    @media (max-width: 900px) {
      margin: 0 1rem;
    }
  }
`

const Author = styled.div`
  font-size: 1rem;
  font-weight: 300;
  color: #737376;
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: 0.38px;
`

interface IAvatar {
  image: string
}

const Avatar = styled<IAvatar, any>('figure')`
  background: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 110px;
  height: 110px;
  display: inline-block;
  margin-top: 40px;
  border-radius: 100%;
`

interface ITestimonialProps {
  flushTop?: boolean
  style?: object
  testimonials: Array<{
    content: string
    name: string
    company: string
    image: string
  }>
}

export default class extends React.Component<ITestimonialProps> {
  state = {
    i: 0
  }

  prev = () => {
    let i = this.state.i - 1
    i = i < 0 ? this.props.testimonials.length - 1 : i
    this.setState({ i })
  }

  next = () => {
    let i = this.state.i + 1
    i = i >= this.props.testimonials.length ? 0 : i
    this.setState({ i })
  }

  render() {
    const { testimonials, ...rest } = this.props
    const testimonial = testimonials[this.state.i]

    return (
      <Container {...rest}>
        <h2>
          What our <i>clients</i> think
        </h2>
        <Testimonial>
          <Flex alignItems="center">
            <Arrow rotate="90deg" size="32px" onClick={this.prev} />
            <p>{testimonial.content}</p>
            <Arrow rotate="-90deg" size="32px" onClick={this.next} />
          </Flex>
          <Author>
            &mdash; {testimonial.name}, {testimonial.company}
          </Author>
        </Testimonial>
        <div className="col-1000">
          <div className="controls">
            <div className="dot" data-id="0">
              <div className="inner" data-id="0" />
            </div>
          </div>
        </div>
        <Flex justifyContent="center" style={{ marginTop: 40 }}>
          <Link to="/testimonials">
            <Button outline={true} small={true}>
              See more reviews
            </Button>
          </Link>
        </Flex>
      </Container>
    )
  }
}
