import * as React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Testimonials from '../components/Testimonials'
import styled from 'styled-components'

export const netballTestimonials = [
  {
    content: `Our gable carport has just been completed and would like to thank you for the outstanding service provided throughout the process. Would highly recommend you.`,
    name: 'Michelle',
    company: 'Morayfield'
  },
  {
    content:
      'Hi Clint, Thank you for your great service. Paul and Matt did a fantastic job and we are very pleased with the finished project. We would happily recommend you guys.',
    name: 'Dave',
    company: 'Caboolture'
  },
  {
    content:
      'Our gable carport has just been completed and would like to thank you for the outstanding service provided throughout the process. Would highly recommend you.',
    name: 'Pam',
    company: 'Boondall'
  },
  {
    content:
      'The guys installing my patio put together a quality & professional job. Worked great as a team, knowing what exactly was to be done and showed an affinity on the job.',
    name: 'Michelle',
    company: 'Morayfield'
  },
  {
    content:
      'It has been 6 weeks since our patio was built and we would like to thank you on a quality job. The builders were polite and friendly and I will happily recommend you.',
    name: 'Fay and Kevin',
    company: 'Muirhead'
  }
]

const StyledIframe = styled.iframe`
  height: 1100px;
  border: 0;
  @media (min-width: 1700px) {
    height: 1100px;
  }

  @media (max-width: 1300px) {
    height: 1100px;
  }
  @media (max-width: 1200px) {
    height: 1200px;
  }
  @media (max-width: 800px) {
    height: 1300px;
  }
  @media (max-width: 570px) {
    height: 1600px;
  }
  @media (max-width: 480px) {
    height: 1900px;
  }
`

const StyledReviews = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 800px;
  overflow: hidden;
  .gradeus-button-container {
    iframe {
      margin-left: 15px;
      @media (max-width: 850px) {
        margin-left: 25px;
        margin-bottom: 20px;
      }
    }
  }
`

export const allTestimonials = [...netballTestimonials]

class Reviews extends React.Component {
  // componentDidMount() {
  //   const method = window.addEventListener ? 'addEventListener' : 'attachEvent'
  //   const event = window.addEventListener ? 'message' : 'onmessage'
  //   window[method](
  //     event,
  //     e => {
  //       if ('review_iframe_height' in e.data) {
  //         document.getElementById('myFrame').height =
  //           e.data.review_iframe_height + 'px'
  //       }
  //     },
  //     false
  //   )
  // }

  componentDidMount() {}
  render() {
    if (typeof document !== 'undefined') {
      !(function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (!d.getElementById(id)) {
          js = d.createElement(s)
          js.id = id
          js.src = '//platform.grade.us/widgets.js'
          fjs.parentNode.insertBefore(js, fjs)
        }
      })(document, 'script', 'gradeus-wjs')

      !(function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (!d.getElementById(id)) {
          js = d.createElement(s)
          js.id = id
          js.src = '//platform.grade.us/widgets.js'
          fjs.parentNode.insertBefore(js, fjs)
        }
      })(document, 'script', 'gradeus-wjs')
    }

    return (
      <Layout>
        <Helmet>
          <title>
            Testimonials | Brisbane & Sunshine Coast’s Builders | Just Patios
          </title>
          <meta
            name="description"
            content="Curious how Just Patios gets the job done? Here are testimonials from a few of our happy customers who have had patio, deck, carport or room enclosure building jobs completed by our team. Discover what Just Patios can do for you."
          />
        </Helmet>
        <Header style={{ paddingTop: '10rem', paddingBottom: '5rem' }}>
          <h1>Customer Reviews</h1>
        </Header>

        {/* <StyledIframe
          width="100%"
          frameborder="0"
          scrolling="no"
          height="1100px"
          id="myFrame"
          src="https://smartbusinessreviews.mylocalreviewsite.com/my-review/index/uid/61c7335de25b40bc84112556143e0ff5"
        /> */}

        <StyledReviews>
          <div className="gradeus-button-container">
            <a
              href="https://grade.us/just-patios-queensland-brisbane-au-4506/"
              className="gradeus-button"
              data-content="Review Us"
              data-replace="true"
            >
              Review Us
            </a>
          </div>
          <div
            className="gradeus-stream"
            data-review-limit="5"
            data-url="https://grade.us/just-patios-queensland-brisbane-au-4506/"
          ></div>
        </StyledReviews>

        <Flex
          flexDirection="column"
          alignItems="center"
          width="100%"
          style={{ margin: '50px 0 100px' }}
        >
          <Testimonials
            testimonials={allTestimonials}
            style={{
              borderRadius: 8,
              boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)'
            }}
          />
        </Flex>
      </Layout>
    )
  }
}

export default Reviews
